import Add from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import DoneOutline from '@mui/icons-material/DoneOutline';
import DrawOutlined from '@mui/icons-material/DrawOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import Search from '@mui/icons-material/Search';
import Cancel from '@mui/icons-material/Cancel';
import Send from '@mui/icons-material/Send';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloudUploadOutlined from '@mui/icons-material/CloudUploadOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import Save from '@mui/icons-material/Save';
import SmartButtonIcon from '@mui/icons-material/SmartButtonOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { NavLink, NavLinkProps } from 'react-router-dom';
import i18n from '../../../libraries/i18n.js';
import { ButtonProps, ButtonWithIcon } from '../../../mui/Button.js';

export type BaseButtonWithIconProps = Omit<ButtonProps, 'title'> & {
  /**
   * @deprecated use children instead. Change when you see.
   */
  title?: string;
};

export function BaseButtonWithIcon({
  onClick,
  variant = 'outlined',
  size = 'medium',
  color = 'primary',
  title,
  children,
  ...props
}: BaseButtonWithIconProps) {
  return (
    <ButtonWithIcon onClick={onClick} variant={variant} size={size} color={color} {...props}>
      {title || children}
    </ButtonWithIcon>
  );
}

export const AddButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon endIcon={<Add />} variant="contained" {...props}>
    {props.children || i18n.t('actions.add')}
  </BaseButtonWithIcon>
);
export const CreateButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon endIcon={<Add />} variant="contained" {...props}>
    {props.children || i18n.t('actions.create')}
  </BaseButtonWithIcon>
);
export const SaveButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon endIcon={<Save />} {...props}>
    {props.children || i18n.t('actions.save')}
  </BaseButtonWithIcon>
);
export const EditButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon endIcon={<EditOutlinedIcon />} {...props}>
    {props.children || i18n.t('actions.edit')}
  </BaseButtonWithIcon>
);
export const SendButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon endIcon={<Send />} variant="contained" {...props}>
    {props.children || i18n.t('actions.send')}
  </BaseButtonWithIcon>
);
export const NotificationButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon endIcon={<NotificationsOutlinedIcon />} {...props}>
    {props.children || i18n.t('singles.notifications')}
  </BaseButtonWithIcon>
);
export const DeleteButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon startIcon={<DeleteIcon />} color="warning" variant="text" type="button" {...props}>
    {props.children || i18n.t('actions.delete')}
  </BaseButtonWithIcon>
);
export const UnlinkButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon endIcon={<LinkOffIcon />} {...props}>
    {props.children || i18n.t('actions.eject')}
  </BaseButtonWithIcon>
);
export const GoToButtonLinkWithIcon = (props: Partial<BaseButtonWithIconProps> & NavLinkProps) => (
  <BaseButtonWithIcon component={NavLink} endIcon={<ArrowForward />} type="button" {...props}>
    {props.children || i18n.t('actions.goTo')}
  </BaseButtonWithIcon>
);

export const SearchButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon endIcon={<Search />} variant="contained" color="secondary" type="button" {...props}>
    {props.children || i18n.t('singles.search')}
  </BaseButtonWithIcon>
);
export const UploadButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon endIcon={<CloudUploadOutlined />} type="button" {...props}>
    {props.children || i18n.t('actions.upload')}
  </BaseButtonWithIcon>
);
export const PreviewButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon endIcon={<VisibilityOutlinedIcon />} type="button" {...props}>
    {props.children || i18n.t('actions.openPreview')}
  </BaseButtonWithIcon>
);
export const DownloadButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon startIcon={<CloudDownloadOutlinedIcon />} type="button" {...props}>
    {props.children || i18n.t('actions.download')}
  </BaseButtonWithIcon>
);
export const CancelButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon startIcon={<Cancel />} type="button" {...props}>
    {props.children || i18n.t('actions.cancel')}
  </BaseButtonWithIcon>
);
export const DoneButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon endIcon={<DoneOutline />} variant="contained" type="button" {...props}>
    {props.children || i18n.t('actions.complete')}
  </BaseButtonWithIcon>
);
export const BackButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon startIcon={<ArrowBackIcon />} type="button" {...props}>
    {props.children || i18n.t('actions.back')}
  </BaseButtonWithIcon>
);
export const SignButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon endIcon={<DrawOutlined />} variant="contained" type="button" {...props}>
    {props.children || i18n.t('actions.sign')}
  </BaseButtonWithIcon>
);
export const CaptureButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon endIcon={<PaymentIcon />} type="button" variant="contained" {...props}>
    {props.children || i18n.t('actions.capture')}
  </BaseButtonWithIcon>
);
export const SmartButtonWithIcon = (props: Partial<BaseButtonWithIconProps>) => (
  <BaseButtonWithIcon endIcon={<SmartButtonIcon />} type="button" variant="contained" {...props}>
    {props.children}
  </BaseButtonWithIcon>
);
