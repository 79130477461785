import { AptlyAvailabilityCalendar, AptlyBooking, AptlyUnit } from '@aptly-as/types';
import { ListItem } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import React from 'react';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DeleteIconButton } from '../../components/actions/icons/Icons';
import { LoadMoreButton } from '../../components/actions/icons/LoadMoreButton';
import { SlugLevel, useApiUrl, useTo } from '../../hooks/useGetApiUrl';
import { intlDateTimeFormat } from '../../libraries/intl';
import { getId } from '../../libraries/mongoose';
import i18n from '../../libraries/i18n';
import { IUseSimpleSearchProps, useSimpleSearch } from '../../libraries/reach/useSimpleSearch';
import { ListItemTextPrimaryWithDate } from '../../components/List/ListItemTextPrimaryWithDate';
import ApiError from '../../components/ApiError';

interface IBookingsProps {
  level: SlugLevel;
}

export function Bookings({ level }: IBookingsProps) {
  const path = useApiUrl(level, 'bookings');
  const props: IUseSimpleSearchProps<AptlyBooking> = useMemo(
    () => ({
      limit: 5,
      query: {
        $gt_from: new Date(),
        sort: 'from',
        $populate: 'unit:_id,name',
        archived: false,
      },
    }),
    []
  );
  const [busy, bookings, error, next, info] = useSimpleSearch<AptlyBooking>(path, props);

  return (
    <>
      {error && <ApiError error={error} />}
      <List
        subheader={
          <ListSubheader>
            {i18n.t('singles.booking')} ({info.count})
          </ListSubheader>
        }
      >
        {bookings.map((booking, i) => (
          <React.Fragment key={booking._id}>
            <BookingListItem booking={booking} i={i} />
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
        {bookings.length < info.count && <LoadMoreButton next={next} disabled={busy} />}
      </List>
    </>
  );
}

interface IBookingListItemProps {
  booking: AptlyBooking;
  i: number;
  onDelete?: (note: AptlyBooking, i: number) => void;
  selected?: boolean;
}

export function BookingListItem({ booking, i, onDelete, selected }: IBookingListItemProps) {
  const to = useTo(SlugLevel.Project, `/motebooking/${getId(booking.availability)}`, {
    project: getId(booking.project),
    unit: getId(booking.unit),
  });
  const handleOnDelete = useCallback(() => {
    if (typeof onDelete === 'function') {
      onDelete(booking, i);
    }
  }, [onDelete, booking, i]);
  const secondaryAction = useMemo(
    () => (onDelete ? <DeleteIconButton onClick={handleOnDelete} /> : undefined),
    [onDelete, handleOnDelete]
  );
  const unit = booking.unit as AptlyUnit;

  return (
    <ListItem component={Paper} disablePadding secondaryAction={secondaryAction}>
      <ListItemButton component={Link} to={to} selected={selected}>
        <ListItemText
          primary={<ListItemTextPrimaryWithDate title={unit.name} date={intlDateTimeFormat(booking.from)} />}
          secondary={(booking.calendar as AptlyAvailabilityCalendar)?.name}
        />
      </ListItemButton>
    </ListItem>
  );
}
